import * as React from 'react';
import { ActivityIndicator, AsyncStorage, Image, Platform, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { withTranslation } from 'react-i18next';
import { AppContext, IContext } from '../store/app-provider';
import ItemsList from "../components/items-list";
import { AntDesign, FontAwesome, FontAwesome5, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { API } from "aws-amplify";
import * as queries from "../graphql/queries";
import ActionSheet, { SheetManager } from 'react-native-actions-sheet';
import Alert from '../components/alert';

import navigationService from "../services/navigation-service";




interface Props {

}

interface State {
    isBusy: boolean
    isEnable: boolean
    password: string
    show: boolean
}
class StatsScreen extends React.Component<Props, State> {
    list: any;
    isEnabled: boolean = false;
    static contextType = AppContext;
    declare context: IContext
    constructor(props: any) {
        super(props);
        this.state = { isBusy: false, isEnable: false, show: false, password: '' }
    }

    async componentDidMount() {
        const showStats = Platform.OS !== "web" ? await AsyncStorage.getItem('showStats') : localStorage.getItem('showStats');
        const showMe = Platform.OS !== "web" ? await AsyncStorage.getItem('showMe') : localStorage.getItem('showMe');
        this.setState({ isEnable: showStats === 'true', show: showMe === 'true' });
    }

    async getMyChats(token?: string) {

        let history = (await API.graphql({
            query: queries.getMessagesByType,
            variables: { type: "user", limit: 20, sortDirection: 'DESC', nextToken: token },
        }) as any).data.getMessagesByType;
        //console.log(history);
        return { items: history.items, nextToken: history.nextToken };
    }
    submitText() {
        if (this.state.password === 'neaion9$') {
            this.setState({ show: true });
            if (Platform.OS !== "web") {
                AsyncStorage.setItem('showMe', 'true');
            } else {
                localStorage.setItem('showMe', 'true');
            }

        }
    }
    componentDidUpdate(prevPops: any) {


    }



    render() {

        const renderItem = ({ item, index }: any) => {
            return <View style={[style.column, style.fullWidth, { marginTop: 10, marginBottom: 10, borderRadius: colors.borderRadius, backgroundColor: colors.pinkish, padding: 20 }]} key={item.id}>
                <View style={[style.row, style.fullWidth, { paddingRight: 10 }]}>
                    <FontAwesome name="user-circle" size={24} style={{ marginRight: 10 }} color={colors.bluePrimary} />
                    <Text style={{
                        fontFamily: 'Jost',
                        fontSize: 18,
                        flexGrow: 1,
                        paddingRight: 20,
                        color: colors.neutralBlue
                    }}>{item.userMessage}</Text>
                </View>
                <View style={[style.row, { marginTop: 20 }]}>
                    {/* <MaterialCommunityIcons name="robot" size={24} style={{ marginRight: 10 }} color={colors.bluePrimary} /> */}
                    <Image style={[{
                        height: 24,
                        width: 24,
                        marginRight: 10
                    }]} source={{ uri: navigationService.props.logo }}></Image>
                    {item.assistantMessage ? <Text style={{
                        fontFamily: 'Jost',
                        flexGrow: 1,
                        paddingRight: 20,
                        fontSize: 18,
                        color: colors.neutralBlue
                    }}>{item.assistantMessage}</Text> : <ActivityIndicator size="small" color={colors.busyIndicator} />}
                </View>
                {item.assistantMessage ? <View style={[style.row, style.fullWidth, { marginTop: 10, justifyContent: 'flex-end' }]}>
                    <TouchableOpacity onPress={() => {
                        this.context.shareMe(item);
                    }}>
                        <AntDesign name="sharealt" size={25} color={colors.bluePrimary} />
                    </TouchableOpacity>
                </View> : null}

            </View>

        }
        const busyIndicator = () => {
            if (this.state.isBusy) {
                return <ActivityIndicator size="large" style={style.busyIndicator} color={colors.busyIndicator} />
            }
        }


        return (

            <View style={style.page}>
                {this.state.isEnable && !this.state.show ?
                    <TextInput onSubmitEditing={() => {
                        this.submitText();
                    }} style={[style.textInput, {
                        backgroundColor: colors.pinkish,
                        textAlignVertical: 'center',
                        fontFamily: 'Jost',
                        color: colors.neutralBlue
                    }]} placeholder="Enter your token" onChangeText={(text) => {
                        this.setState({ password: text });
                    }
                    }></TextInput> :
                    <View style={[style.container]}>
                        {this.state.show ? <ItemsList disableBottomScroll={true} ref={(ref) => {
                            this.list = ref;
                        }} cache={false} renderItem={renderItem} stateKey={'statsList'} fn={this.getMyChats.bind(this)} nextTokenName={'nextToken'} stateItem={this.context.state.statsList}></ItemsList> : null}

                    </View>}
                {busyIndicator()}

            </View>

        )
    };

}

export default (withTranslation()(StatsScreen));

const styles = StyleSheet.create({
    input: {
        padding: 5,
        height: 35,
        width: '100%',
        borderBottomWidth: 1,
        borderBottomColor: colors.textColor,
        color: colors.textColor
    },
    screenItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        borderRadius: 10,
        width: '100%',
        height: '100%',
        backgroundColor: colors.textColor
    }
});