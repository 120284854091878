import { AntDesign, Entypo, Feather, FontAwesome, FontAwesome5, Ionicons, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import * as React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  View,
  FlatList,
  Platform,
  Clipboard,
  RefreshControl,
  AsyncStorage, Image, Modal, BackHandler, ScrollView, TextInput, ActivityIndicator, Keyboard
} from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import navigationService from './../services/navigation-service';
import { AppContext, IContext } from '../store/app-provider';
import { SheetManager } from "react-native-actions-sheet";
import ItemsList from "../components/items-list";
import { API } from "aws-amplify";


interface Props {

}

interface State {
  type: string

}

class HomeScreen extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext
  itemList: any

  constructor(props: any) {
    super(props);
    this.state = { type: 'public' }
  }

  createPost() {
    if (Platform.OS === "web") {
      SheetManager.show('download_sheet_home');
      return;
    }


  }
  copyPaste = async (text: string) => {
    Clipboard.setString(text)
    this.context.showToast('Noah, am copiat!', 'success');
    this.context.logEvent('copy_message');
  }
  async componentDidMount() {
    navigationService.props.setHome = true;
  }

  componentDidUpdate(prevPops: any) {

  }

  async getHistory(token?: string) {
    const history = { items: [], nextToken: '' }
    return { items: history.items, nextToken: history.nextToken };
  }
  clearChat() {
    this.context.clearChat();
  };
  reportUser() {
  }

  setPrivate(e: any) {
    if (e.type !== this.state.type) {
      this.setState({ type: e.type }, () => {
        this.itemList.refresh();
      })
    }

  }
  async submitText() {

    if (Platform.OS === "web" && (window as any)?.document.location.href?.indexOf('localhost') === -1) {
      SheetManager.show('download_sheet_home');
      return;
    }
    if (!this.context.state.userPrompt || this.context.state.isBusy) return;
    try {
      await this.context.getChatMessage();

    } catch (e) {
    }
  }

  render() {
    const busyIndicator = () => {
      if (this.context.state.isBusy) {
        return <ActivityIndicator size="large" style={style.busyIndicator} color={colors.busyIndicator} />
      }
    }
    const renderItem = ({ item, index }: any) => {
      return <View style={[style.column, style.fullWidth, { marginTop: 10, marginBottom: 10, borderRadius: colors.borderRadius, backgroundColor: colors.pinkish, padding: 20 }]} key={item.id}>
        <View style={[style.row, style.fullWidth, { paddingRight: 10 }]}>
          <FontAwesome name="user-circle" size={24} style={{ marginRight: 10 }} color={colors.bluePrimary} />
          <Text style={{
            fontFamily: 'Jost',
            fontSize: 18,
            flexGrow: 1,
            paddingRight: 20,
            color: colors.neutralBlue
          }}>{item.user}</Text>
        </View>
        <View style={[style.row, style.fullWidth, { marginTop: 20 }]}>
          {/* <MaterialCommunityIcons name="robot" size={24} style={{ marginRight: 10 }} color={colors.bluePrimary} /> */}
          <Image style={[{
            height: 24,
            width: 24,
            marginRight: 10
          }]} source={{ uri: navigationService.props.logo }}></Image>
          {item.ai ? <Text style={{
            fontFamily: 'Jost',
            flexGrow: 1,
            paddingRight: 20,
            fontSize: 18,
            color: colors.neutralBlue
          }}>{item.ai}</Text> : <ActivityIndicator size="small" color={colors.busyIndicator} />}
        </View>
        {item.ai ? <View style={[style.row, style.fullWidth, { marginTop: 10, justifyContent: 'flex-end' }]}>
          <TouchableOpacity style={{ marginRight: 10 }} onPress={() => {
            this.copyPaste(item.ai);
          }}>
            <Feather name="clipboard" size={25} color={colors.bluePrimary} />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => {
            this.context.shareMe(item);
          }}>
            <AntDesign name="sharealt" size={25} color={colors.bluePrimary} />
          </TouchableOpacity>
        </View> : null}
        {!this.context.state.isPremium && item.ai && (index || 0) !== 0 && ((index || 0)) % this.context.state.adData?.banSkip === 0 && this.context.state.adData?.enabled && this.context.state.checkedStore && Platform.OS !== 'web' && this.context.state.appConfig ?
          <View style={[{
            marginTop: 10,
            backgroundColor: 'transparent',
            height: 60,
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 0,
            width: '100%'
          }]}>
            <navigationService.props.BannerAd onAdFailedToLoad={() => {
            }}
              unitId={this.context.state.adData?.ban}
              size={navigationService.props.BannerAdSize.BANNER}
              requestOptions={{
                requestNonPersonalizedAdsOnly: this.context.state.requestNonPersonalizedAdsOnly
              }}
            />
          </View> : null}
      </View>

    }
    return (
      <View style={style.page}>
        <View style={[style.container, { padding: 10 }]}>
          <ItemsList ref={ref => {
            this.itemList = ref
          }} cache={false} renderItem={renderItem} disableRefresh={true} stateKey={'chatsList'} fn={this.getHistory.bind(this)} nextTokenName={'nextToken'} stateItem={this.context.state.chatsList}></ItemsList>
          <View style={[style.row, style.fullWidth, style.vcenter, { opacity: this.context.state.isBusy ? 0.5 : 1, maxWidth: '100%' }]}>

            <TouchableOpacity style={{ marginRight: 10 }} onPress={() => {
              this.clearChat();
            }}>
              <MaterialCommunityIcons name="chat-plus-outline" size={30} color={colors.bluePrimary} />
            </TouchableOpacity>
            <View style={{ height: '100%', flex: 1, marginRight: 10 }}>
              <TextInput
                autoCapitalize={'sentences'}
                value={this.context.state.userPrompt || ''}
                autoCorrect={false}
                blurOnSubmit={true}
                maxLength={250}
                enablesReturnKeyAutomatically={true}
                onSubmitEditing={() => {
                  this.submitText();
                }}
                placeholder='Noah, întreabă-mă ce vrei...'
                multiline={true}
                numberOfLines={3}
                onChangeText={(text: string) => {
                  this.context.setAppProp({ userPrompt: text })
                }}
                style={[style.textInput, {
                  backgroundColor: colors.pinkish,
                  textAlignVertical: 'center',
                  height: 80,
                  flexGrow: 1,

                  fontFamily: 'Jost',
                  color: colors.neutralBlue,
                  width: '100%', // Add this line
                }]}
              ></TextInput>
            </View>
            <TouchableOpacity onPress={() => {
              this.submitText();
              //hide keyboard
              Keyboard.dismiss();

            }}>
              <MaterialCommunityIcons name="send" size={30} color={colors.bluePrimary} />
            </TouchableOpacity>
          </View>

        </View>

      </View>
    )

  }
}

export default HomeScreen


