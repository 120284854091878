import React, { Component } from "react";
import navigationService from "../services/navigation-service";
import { Alert, AppState, AsyncStorage, BackHandler, Dimensions, Linking, Platform, Share } from "react-native";

import { InAppBrowser } from "react-native-inappbrowser-reborn";
import { Amplify, API, Auth, graphqlOperation } from "aws-amplify";
import config from "./../aws-exports";
import * as queries from "../graphql/queries";
import publicIP from 'react-native-public-ip';
const CryptoJS = require('crypto-js');

import {
  createThreads, createChats, deleteThreads
} from "./../graphql/mutations";
const AWS = require('aws-sdk');
import Lambda from 'aws-sdk/clients/lambda';


import DeviceInfo from "react-native-device-info";
import { SheetManager } from "react-native-actions-sheet";
import InAppReview from 'react-native-in-app-review';
import { Storage } from "aws-amplify";
import colors from "../constants/colors";
import analytics from "@react-native-firebase/analytics";
import ReactGA from "react-ga4";
///@ts-ignore
import { v4 as uuidv4 } from 'uuid';
import { TumblrShareButton } from "react-share";
import { Threads } from "../models";
export interface IAppState {
  showStats?: boolean;
  sharePost?: any
  currentThread?: Threads;
  deviceId: string;
  thread: string;
  chatMessages: any[];
  userPrompt: string;
  publicIp?: string;
  requestNonPersonalizedAdsOnly: boolean,
  appMenuOpened?: boolean;
  appMenuOpenedBack?: boolean;
  packageName?: any;
  checkedStore?: boolean
  adData?: any
  isAdPlaying?: boolean,
  receivedReward?: boolean
  isAdmin?: boolean;
  os?: string
  shareUrl?: string,
  hasShownPolicy: string;
  reportUser: any;
  routeParams: any,
  isPlaying: boolean,
  shareModalVisible?: boolean,
  chatsList: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  historyList: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  statsList: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  isBusy?: boolean
  appConfig?: any
  screen?: any
  isPremium: boolean;
  isMobile: boolean;
  isTablet: boolean;
  userPicture: string;
  isPortrait: boolean;
}

export interface IProps {
  Toast: any
}

export interface IContext {
  state: IAppState,
  hideMenu: () => void,
  signOut: () => void,
  gotoStore: () => void,
  clearChat: () => void,
  getAIMessage: (body: any) => void,
  getChatMessage: (force?: boolean) => void,
  deleteThread: () => void,
  logEvent: (name: string) => void
  gotoChatHistory: (threadItem: any) => void,
  showDeleteThread: (threadId: string) => void,
  sendMail: (emailTo: string, subject: string, message: string) => void,
  appReview: () => void,
  buyPremiumSubscription: (isYearly?: boolean) => void,
  restorePurchase: () => void,
  playRewardedAdAndGenerate: (callback?: any) => void,
  showRewarded: (callback?: any) => void,
  playRewardedAndGo: () => void,
  createAd: (addType: string, callback?: any, onClose?: any) => void,
  updateAccount: (data: any) => void,
  shareMe: (messageId: string) => void,
  getListItems: (stateKey: any, fn: any, nextTokenName: string, refresh: boolean) => void,
  setScreen: (screen: string, navigate?: boolean, params?: any, callback?: any) => void
  setAppProp: (obj: any, callback?: any) => void,
  showToast: (message: string, type: 'error' | 'success') => void,
  setUser: (user: any) => void
}

const initialState: any = {
  requestNonPersonalizedAdsOnly: false,
  chatsList: { items: [], loading: false, refreshing: false },
  historyList: { items: [], loading: false, refreshing: false },
  statsList: { items: [], loading: false, refreshing: false },
  appConfig: {},
  isBusy: false
}

export const AppContext = React.createContext<IContext>(initialState);
export const AppContextProvider = AppContext.Provider

export class AppProvider extends Component<IProps, any> {
  state = initialState
  tmAd: any
  firstScreen: any
  toDeleteThreadId: any;
  goRewardedCallback: any;
  doNotPlayAd: boolean = false;
  tm?: any
  private canPlay: boolean = false;
  showedOnceCampaign: boolean = false;

  constructor(props: IProps) {
    super(props);

  }
  showDeleteThread = async (threadId: any) => {
    this.toDeleteThreadId = threadId;
    SheetManager.show("delete_thread");
  }
  deleteThread = async () => {
    if (this.toDeleteThreadId) {
      await API.graphql(graphqlOperation(deleteThreads, { input: { id: this.toDeleteThreadId } }))
      this.toDeleteThreadId = undefined;
    }
  }
  gotoChatHistory = async (threadItem: any) => {
    this.state.currentThread = threadItem;
    this.state.thread = threadItem.threadId;
    this.setScreen("Home", true);
    let history = (await API.graphql({
      query: queries.getMessagesByThreadId,
      variables: { threadId: this.state.currentThread.id, limit: 100, sortDirection: 'ASC' },
    }) as any).data.getMessagesByThreadId;
    let historyItems = history.items;
    let chatsList = { nextToken: "", items: [], loading: false, refreshing: false }
    chatsList.items = historyItems.map((item: any) => {
      return { user: item.userMessage, ai: item.assistantMessage, id: item.id }
    });
    this.setState({ chatsList, thread: threadItem.threadId, currentThread: threadItem })

    console.log("historyItems", historyItems)
  }
  clearChat = async () => {
    let chatsList = { nextToken: "", items: [], loading: false, refreshing: false }
    this.setState({ chatsList, thread: "", currentThread: undefined })
  }
  getChatMessage = async (force?: boolean) => {
    let generatedMessages;
    if (Platform.OS !== "web") {
      generatedMessages = parseInt(await AsyncStorage.getItem("generatedMessages") || "0") || 0;
    } else {
      generatedMessages = 0;
    }
    console.log("showRewarded 0")
    if (!force && generatedMessages % this.state.adData?.genBetween === 0 && generatedMessages >= this.state.adData?.maxGen && !this.state.isPremium && this.state.adData?.enabled && this.state.checkedStore && Platform.OS !== 'web') {
      console.log("showRewarded 1")
      this.showRewarded(this.getChatMessage.bind(this, true))
      return;
    }
    this.setState({ isBusy: true })
    if (!this.state.thread) {
      try {
        const result: any = await this.getAIMessage({ op: "init-thread" });
        const thread = result.outputResult.threadId;
        const assistantId = result.outputResult.assistantId;
        console.log("thread", thread)
        let newThread: any = {
          savedAt: new Date().toISOString(),
          threadId: thread,
          assistantId: assistantId,
          deviceId: this.state.deviceId,
          message: this.state.userPrompt
        };
        const createThreadsResult = await API.graphql(graphqlOperation(createThreads, { input: newThread })) as any;
        let currentThread: any = createThreadsResult.data.createThreads
        this.state.thread = thread;
        this.state.currentThread = currentThread;
        this.setState({ thread, currentThread })
        this.logEvent("new_chat")
      } catch (e) {
        this.setState({ isBusy: false })
        this.showToast("Noah, ceva n-o mers bine, te rog incearcă din nou.", "info")
        return e;
      }
    }

    console.log("getAIMessage", this.state.userPrompt, this.state.thread)
    let message: any = {};
    message.user = this.state.userPrompt;
    message.ai = "";
    message.id = uuidv4();
    const chatsList = { ...this.state.chatsList };
    const chatsListItems = this.state.chatsList.items.concat(message);
    chatsList.items = chatsListItems;
    console.log("chatsList", chatsList)
    this.setState({ chatsList: chatsList });

    let aiMessage: any
    try {
      aiMessage = await this.getAIMessage({ op: "message", threadId: this.state.thread, message: this.state.userPrompt });
      const ionMessage = aiMessage.outputResult?.content[0].text.value;
      if (!ionMessage) {
        this.setState({ isBusy: false })
        return;
      }
      message.ai = ionMessage;
      //refresh the state
      chatsListItems[chatsListItems.length - 1] = message;
      chatsList.items = chatsListItems;
      this.setState({ chatsList: chatsList });
      //console.log("aiMessage", aiMessage)


      let newChat = {
        deviceId: this.state.deviceId,
        threadId: this.state.currentThread.id,
        assistantId: this.state.currentThread.assistantId,
        assistantMessage: ionMessage,
        userMessage: this.state.userPrompt,
        type: "user",
        savedAt: new Date().toISOString()
      }
      const chatMessage = (await API.graphql(graphqlOperation(createChats, { input: newChat })) as any).data.createChats;
      message.id = chatMessage.id;
      this.setState({ isBusy: false, userPrompt: "" })
    } catch (e) {
      //remove the last message
      chatsListItems.pop();
      chatsList.items = chatsListItems;
      this.setState({ isBusy: false, chatsList })
      this.showToast("Noah, ceva n-o mers bine, te rog incearcă din nou.", "info")
      return e;
    }

    if (Platform.OS !== "web") {
      generatedMessages++;
      AsyncStorage.setItem("generatedMessages", generatedMessages.toString());
      const hasReview = await AsyncStorage.getItem(this.state.appConfig.reviewKey || "hasReview");
      if (!hasReview && generatedMessages >= this.state.appConfig.reviewAfterMessagesNumber) {
        AsyncStorage.setItem(this.state.appConfig.reviewKey || "hasReview", "true");
        SheetManager.show('app_review');
      }

    } else {
      generatedMessages = 0;
    }

  }
  async getAIMessage(body: any) {

    return new Promise(async (resolve, reject) => {
      const isTest = true;
      if (isTest) {
        /* config.aws_cloud_logic_custom[0].endpoint = "http://127.0.0.1:3002/"
        Amplify.configure(config); */
        Amplify.API.post('neaioanerest', `/magic/`, { body })
          .then(function (result: any) {
            //console.log("result", result);
            resolve(result);
          })
          .catch(function (error: any) {
            console.log("error", error);
            reject(error);
          });
      } else {
        const credentials = await Amplify.Auth.currentCredentials()
        AWS.config.update({
          region: 'us-east-1',
          accessKeyId: credentials.accessKeyId,
          secretAccessKey: credentials.secretAccessKey
        });
        const lambda = new AWS.Lambda({
          credentials: Amplify.Auth.essentialCredentials(credentials),
          httpOptions: {
            timeout: 15 * 60 * 1000
          }
        });
        lambda.invoke({
          FunctionName: 'neaioane-dev',
          InvocationType: 'RequestResponse',
          Payload: JSON.stringify({ body }),
        }, (err: any, data: any) => {
          if (err) {
            console.log(err);
          } else {
            const result = JSON.parse(data.Payload)
            //console.log("result", result);
            const body = JSON.parse(result.body);
            ///console.log("body", body);
            resolve(body);
            if (body.message) {
              //reject({ message: body.message });
            } else {
              //resolve(body);
            }

          }
        });
      }
    })

  }
  gotoStore() {
    if (this.state.os === 'ios') {
      //window.open(this.state.appConfig.iOSApp)
      Linking.openURL(this.state.appConfig.iOSApp);

    } else {
      //window.open(this.state.appConfig.androidApp)
      Linking.openURL(this.state.appConfig.androidApp);
    }
  }

  sendNewsletter = async () => {

    config.aws_cloud_logic_custom[0].endpoint = "http://127.0.0.1:3002/"
    Amplify.configure(config)
    const message = `
       `;
    const result = await API.post('lyritunesrest', '/rest/send-blast-email', {
      body: { subject: "🎉 LyriTunes - Updating and Upgrading", message, isTest: true, templateId: "LyriTunesTemplate3" }
    })
    console.log("result", result)
  }
  uploadMusic = async () => {
    config.aws_appsync_authenticationType = "API_KEY";
    config.aws_cloud_logic_custom[0].endpoint = "http://127.0.0.1:3002"
    Amplify.configure(config);
    const result = await API.post('lyritunesrest', '/rest/upload-music/', {
      body: {
        category: "inspiring"
      }
    })
    console.log("Done")



  }

  async componentDidMount() {
    let deviceId = "";
    if (Platform.OS === "web") {
      deviceId = localStorage.getItem("deviceId") || uuidv4();
      localStorage.setItem("deviceId", deviceId);
    } else {
      deviceId = await AsyncStorage.getItem("deviceId") || uuidv4();
      await AsyncStorage.setItem("deviceId", deviceId);
    }

    //console.log("deviceId", deviceId)

    const ip = await publicIP();
    this.setState({ publicIp: ip });
    if (Platform.OS === 'web') {
      window.addEventListener("resize", this.checkOrientation.bind(this));
      this.checkOrientation();
    } else {
      if (Platform.OS === 'android') {
        this.listenBackButton();
      }
      Dimensions.addEventListener('change', () => {
        this.checkOrientationMobile();
      });
      this.checkOrientationMobile();
    }
    this.getAppConfig();

    this.setState({ deviceId })

  }

  isPortrait = () => {
    const dim = Dimensions.get('window');
    return dim.height >= dim.width;
  };
  getAd = (addType: string, callback?: any) => {
    let ad;
    //console.log("getAd", addType, this.state.adData[addType])
    switch (addType) {
      case "re":
        ad = navigationService.props.RewardedAd.createForAdRequest(this.state.adData[addType], {
          requestNonPersonalizedAdsOnly: this.state.requestNonPersonalizedAdsOnly
        });
        break;
      case "in":
        ad = navigationService.props.InterstitialAd.createForAdRequest(this.state.adData[addType], {
          requestNonPersonalizedAdsOnly: this.state.requestNonPersonalizedAdsOnly
        });
        break;
      case "inre":
        ad = navigationService.props.RewardedInterstitialAd.createForAdRequest(this.state.adData[addType], {
          requestNonPersonalizedAdsOnly: this.state.requestNonPersonalizedAdsOnly
        });
        break;
      case "op":
        ad = navigationService.props.AppOpenAd.createForAdRequest(this.state.adData[addType], {
          requestNonPersonalizedAdsOnly: this.state.requestNonPersonalizedAdsOnly
        });
        break;
    }
    return ad;
  }
  createAd = (addType: string, callback?: any, onClose?: any) => {
    let ad: any;

    if (Platform.OS === "web" || this.state.isPremium) return;
    if (addType === 'open') {
      ad = this.getAd(this.state.adData['openType']);
    } else {
      ad = this.getAd(addType);
    }
    //console.log("createAd", addType, ad)

    try {
      ad.addAdEventListener('rewarded_loaded', () => {
        ad.show();
        this.doNotPlayAd = true;
        this.setState({ isAdPlaying: true, receivedReward: false })
        if (callback) callback(ad);
      });
    } catch (e) {
    }
    try {
      ad.addAdEventListener('loaded', () => {
        ad.show();
        this.doNotPlayAd = true;
        this.setState({ isAdPlaying: true });
        if (callback) callback(ad);
      });
    } catch (e) {
    }
    try {
      ad.addAdEventListener('closed', () => {

        this.setPlayAd(false)
        this.setState({ isAdPlaying: false })
        if (onClose && addType === "in") {
          onClose()
        };

      });
    } catch (e) {
    }
    try {
      ad.addAdEventListener('error', (e: any) => {
        console.log("error", e)
        this.setPlayAd(false)
        this.setState({ isAdPlaying: false, receivedReward: true }, () => {
          if (onClose) onClose();
        })


      });
    } catch (e) {
    }
    try {
      ad.addAdEventListener('rewarded_earned_reward', () => {
        this.setPlayAd(false)
        this.setState({ isAdPlaying: false, receivedReward: true }, () => {
          if (onClose) onClose();
        })

      });
    } catch (e) {
    }
    ad.load();
    return ad;
  }
  listenBackButton = () => {
    const me = this;
    const backHandler = BackHandler.addEventListener('hardwareBackPress', function () {
      console.log("back pressed", me.state.screen)
      if (me.state.viewPost) {

        return true;
      } else if (me.state.appMenuOpened) {
        me.hideMenu();
        backHandler.remove();
        me.listenBackButton();
        return true;
      } else {
        if ((me.state.screen === 'Home' || !me.state.screen)) {
          //throw {};
          backHandler.remove();
          me.listenBackButton();
          BackHandler.exitApp();
          return true;
        }
      }
      if (!navigationService.props.setHome && !me.state.viewPost && !me.state.appMenuOpened) {
        //(store.dispatch as ThunkDispatch<any, void, ActionTypes>)(setScreen('Home', {}, false));
        navigationService.resetRoutes();
        navigationService.props.setHome = true;
        backHandler.remove();
        me.listenBackButton();
        return true;
      }



      return false;
    });
  }
  showRewarded = (callback?: any) => {
    console.log("showRewarded")
    this.goRewardedCallback = callback;
    SheetManager.show('go_ad_view');
  }
  playRewardedAndGo = () => {
    this.playRewardedAdAndGenerate(this.goRewardedCallback);
  }
  playRewardedAdAndGenerate = (callback?: any) => {
    this.createAd('re', (ad: any) => {
      ad.show();
    }, () => {
      if (this.state.receivedReward) {
        callback();
      } else {
        this.showToast("Nu ai primit o recompensă, te rugăm să aștepți până când reclama se termină de rulat și recompensa este primită!", "error")
      }
    })
  }

  detectMob() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }




  getListItems = async (stateKey: any, fn: any, nextTokenName: string, refresh: boolean) => {
    const obj = { ...this.state[stateKey] }
    //console.log("nextTokenName", obj.nextToken)
    if (!refresh && !obj.nextToken) return;
    //obj.loading = !refresh || !obj.items.length;

    obj.loading = !obj.items.length;
    obj.refreshing = refresh && obj.items.length !== 0;
    if (refresh) {
      obj.items = [];
    }
    //console.log("START !!!")
    this.setState({ [stateKey]: { ...obj } });
    let items = refresh ? await fn() : await fn(obj.nextToken);
    //console.log("FETCH !!!", items.items.length)
    //console.log("getListItems", JSON.stringify(items));
    obj.items = refresh ? items.items : obj.items.concat(items.items)
    //make sure obj.items contains unique objects with videoId
    obj.items = obj.items.filter((item: any) => {
      const postReports = item.reports?.length || 0;
      const userReports = item.user?.reports?.length || 0;
      const isBlockedByMe = item.user?.reports?.includes(this.state.user?.id);
      return postReports < 10 && userReports < 10 && !isBlockedByMe;
    })
    obj.nextToken = items[nextTokenName]
    obj.loading = false;
    obj.refreshing = false;
    this.setState({ [stateKey]: obj });
  }

  checkOrientationMobile() {
    const orientation = this.isPortrait() ? 'portrait' : 'landscape'
    this.setState({
      orientation,
      isMobile: true,
      isTablet: DeviceInfo.isTablet(),
      isPortrait: orientation === "portrait"
    });
  }



  checkOrientation() {
    let os;
    if (navigator.userAgent.toLowerCase().indexOf("android") !== -1) {
      os = "android";
    } else if (navigator.userAgent.match(/(iPad|iPhone|iPod)/g)) {
      os = "ios";
      console.log("IOS")
    } else {
      os = "kaka";
    }
    const orientation = window.innerHeight > window.innerWidth ? 'portrait' : 'landscape'
    this.setState({
      orientation,
      os,
      isMobile: this.detectMob(),
      isTablet: window.innerWidth > 1024,
      isPortrait: orientation === 'portrait'
    });

  }

  shareMe = async (message: any) => {
    const url = `https://nea-ion.com/message?id=${message.id}`;
    //const url = `http://localhost:19006/message?id=${message.id}`;
    console.log("url", url)
    if (Platform.OS === "web") {
      if (!this.state.isMobile) {
        this.setState({ shareModalVisible: true, shareUrl: url, sharePost: message })
      } else {
        navigator.share({ url });
      }

    } else {
      this.setPlayAd(true);
      await Share.share({
        title: message.ai,
        message: url,
        url: url
      });
      this.setPlayAd(false);
    }
    this.logEvent("share_message")

  }
  openai = async (prompt: string, max_tokens: number): Promise<any> => {
    const credentials = await Auth.currentCredentials()
    AWS.config.update({
      region: 'us-east-1',
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey
    });
    const lambda = new Lambda({
      credentials: Auth.essentialCredentials(credentials)
    });
    return new Promise((resolve, reject) => {
      lambda.invoke({
        FunctionName: 'lyritunesopenai-prod',
        Payload: JSON.stringify({ prompt, max_tokens }),
      }, (err, data: any) => {
        if (err) {
          reject(err);
        } else {
          resolve(JSON.parse(data.Payload).body.text);
        }
      });
    })
  }


  getAppConfig = async () => {
    let appConfig = (await API.get('neaioanerest', '/rest/config', {})).config;
    const bytes = CryptoJS.AES.decrypt(appConfig, "kXp2s5v8y/B?E(G+KbPeShVmYq3t6w9z369kaka");
    appConfig = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    //console.log("appConfig", appConfig)
    let adData = Platform.OS !== 'web' ? appConfig.adds[Platform.OS.toString()] : {};
    //console.log("adData", adData)
    let hasShownPolicy = Platform.OS !== "web" ? (await AsyncStorage.getItem("hasShownPolicy2")) : await localStorage.getItem("hasShownPolicy2");
    hasShownPolicy = hasShownPolicy ? 'true' : 'false';
    this.setState({ appConfig, adData }, () => {
      this.initStore();
    });
    /* if (this.state.screen !== "View") {
      setTimeout(() => {
        this.setState({ hasShownPolicy });
      }, 500);
    } */

    //console.log("appConfi", appConfig)
    if (Platform.OS !== 'web') {
      let version = DeviceInfo.getVersion();
      //construct the build number based on version string like for example 0.1.0 will be 10
      let iosBuildNumber = parseInt(version.split(".").join(""));
      let buildNumber = Platform.OS === 'android' ? parseFloat(DeviceInfo.getBuildNumber()) : iosBuildNumber;
      const upVersion = appConfig.versions[Platform.OS];
      if (upVersion > buildNumber) {
        this.showUpdateAlert(appConfig);
      }
      //console.log("upVersion", buildNumber, upVersion)
    }
  }
  initStore = async () => {

    if (Platform.OS === "web") {
      this.setState({ checkedStore: true });
      return;
    }
    let isPremium = false;
    const prodSubId = Platform.OS === "android" ? "com.can.neaionsub:neaionsubplan" : "com.can.neaionsub";
    await navigationService.props.adapty.activate('public_live_7Dl9R4xg.OPiPkXoBPLqu0BQA63Se');
    try {
      const info = await navigationService.props.adapty.getProfile();
      console.log("subscription info", JSON.stringify(info))
      if ((info?.subscriptions?.[prodSubId]?.isActive && !info?.subscriptions?.[prodSubId]?.isRefund)) {
        isPremium = true;
        console.log("premium")
      }
    } catch (error: any) {
      console.log("error", error);
      //toast.show(error.localizedDescription, { type: 'error' })
      this.showToast(error.localizedDescription, "error")
    }

    //isPremium = false;

    this.setState({ checkedStore: true, isPremium: isPremium })

    if (!isPremium) {
      if (!navigationService.props.deniedTrackingTransparency) {
        const {
          consentStatus,
          isConsentFormAvailable,
          isRequestLocationInEeaOrUnknown,
        } = await navigationService.props.UMP.requestConsentInfoUpdate()
        if (
          isRequestLocationInEeaOrUnknown &&
          isConsentFormAvailable &&
          consentStatus === (navigationService.props.UMP.CONSENT_STATUS.REQUIRED)
        ) {
          const { consentStatus } = await navigationService.props.UMP.showConsentForm()
          AsyncStorage.setItem("consentStatus", consentStatus.toString());

        }
        const consentStatus2 = await AsyncStorage.getItem("consentStatus");
        console.log("consentStatus2", consentStatus2)
        if (consentStatus2 !== "3" && consentStatus2) {
          this.setState({ requestNonPersonalizedAdsOnly: true })
        }
      } else {
        this.setState({ requestNonPersonalizedAdsOnly: true })
      }


      let generatedMessages = parseInt(await AsyncStorage.getItem("generatedMessages") || "0") || 0;
      if (generatedMessages >= this.state.adData.maxGen) {
        this.playIntroAd();
        if (this.state.adData.hasIntroAd2) {

          AppState.addEventListener("change", async (nextAppState: any) => {
            if (nextAppState === "active") {
              //this.playIntroAd();
            }

          });
        }
      }

    }

  }

  playIntroAd = async () => {
    if (this.state.isPremium || this.doNotPlayAd) return;
    this.doNotPlayAd = true;
    if (this.tmAd) clearTimeout(this.tmAd);
    this.tmAd = setTimeout(() => {
      this.createAd('op', (ad: any) => {
        setTimeout(() => {
          this.doNotPlayAd = false;
        }, 60 * 1000)
      }, () => {

      })
    }, 1000);
  }

  setPlayAd(doNot?: boolean) {
    if (doNot) {
      this.doNotPlayAd = true;
    } else {
      if (this.tmAd) clearTimeout(this.tmAd);
      this.tmAd = setTimeout(() => {
        this.doNotPlayAd = false;
      }, 3000);
    }

  }


  buyPremiumSubscription = async (isYearly?: boolean) => {
    console.log("buyPremium")
    try {


      this.setPlayAd(true);
      let packName = "";
      if (!isYearly) {
        packName = Platform.OS === "android" ? "com.can.neaionsub" : "com.can.neaionsub";

      } else {
        packName = Platform.OS === "android" ? "com.lyritunes.premiumsubyearly" : "com.lyritunes.premiumsubyearly";
      }
      const paywall = await navigationService.props.adapty.getPaywall('PlacementID');
      const products = await navigationService.props.adapty.getPaywallProducts(paywall);
      console.log("products", products)
      const adaptyProduct = products.find((p: any) => p.vendorProductId === packName) || products[0];
      const {
        receipt,
        purchaserInfo,
        product
      }: any = await navigationService.props.adapty.makePurchase(adaptyProduct);
      console.log("receipt", receipt);
      console.log("purchaserInfo", JSON.stringify(purchaserInfo));
      console.log("product", JSON.stringify(product));
      const info = await navigationService.props.adapty.getProfile();

      console.log("info", JSON.stringify(info))
      const checkPackName = Platform.OS === "android" ? "com.can.neaionsub:neaionsubplan" : "com.can.neaionsub";
      if (info?.subscriptions?.[checkPackName]?.isActive && !info?.subscriptions?.[checkPackName]?.isRefund) {
        this.showToast("Succes, acum ești abonat Premium la Nea Ion!", "success");
        this.setState({ isPremiumSubscriber: true, isPremium: true })
        this.logEvent(`buy_${isYearly ? 'yearly' : 'monthly'}_premium_subscription`);
        this.setPlayAd(false);
        this.sendBuyMail(true, isYearly);
      }


    } catch (error: any) {
      console.log("error", error);
      //toast.show(error.localizedDescription, { type: 'error' })
      this.showToast(error.localizedDescription || error.message, "error")

      //this.showToast(error.localizedDescription, "error");
    }

  };
  sendBuyMail = async (isSub: boolean, isYearly?: boolean) => {
    return;
    try {
      await API.post('lyritunesrest', `/rest/send-email/`, {
        body: {
          emailFrom: 'no-reply@lyritunes.com',
          emailTo: 'cristian.anderes.radu@gmail.com',
          message: `Sucessfully bought premium`,
          subject: `LyriTunes Buy ${isSub ? (isYearly ? 'Yearly Sub' : 'Monthly Sub') : 'Premium'} - ${Platform.OS.toString()}!`
        }
      })
    } catch (e: any) {
      console.log("error", e)
    }
  }
  sendMail = async (emailTo: string, subject: string, message: string) => {
    return;
    try {
      await API.post('lyritunesrest', `/rest/send-email/`, {
        body: {
          emailFrom: 'no-reply@lyritunes.com',
          emailTo: emailTo,
          message: message,
          subject: subject
        }
      })
    } catch (e: any) {
      console.log("error", e)
    }
  }
  restorePurchase = async () => {
    console.log("restore")
    const prodSubId = Platform.OS === "android" ? "com.can.neaionsub:neaionsubplan" : "com.can.neaionsub";

    try {
      const paywall = await navigationService.props.adapty.getPaywall('PlacementID');
      console.log("paywall", paywall)

      const products = await navigationService.props.adapty.getPaywallProducts(paywall);
      console.log("products", products)
      await navigationService.props.adapty.restorePurchases()
      const info = await navigationService.props.adapty.getProfile();
      console.log("info", JSON.stringify(info))
      if ((info?.subscriptions?.[prodSubId]?.isActive && !info?.subscriptions?.[prodSubId]?.isRefund)) {
        this.setState({ isPremium: true })
        this.showToast("Succes, acum ești abonat Premium la Nea Ion!", "success")
        console.log("premium sub")
        return;

      }




    } catch (error: any) {
      console.log("error", error);
      this.showToast(error.localizedDescription, "error")
    }

  };
  showUpdateAlert = (appConfig: any) => {
    Alert.alert(
      'Neața!',
      `Noah, vezi că este o versiune nouă, dă-i bice dacă vrei să mai vorbim!`,
      [

        {
          text: 'Dă-i bice',
          onPress: () => {
            Linking.openURL(Platform.OS === 'android' ? appConfig.androidApp : appConfig.iOSApp);
            setTimeout(() => {
              this.showUpdateAlert(appConfig);
            }, 1000);

          }
        },
      ],
      { cancelable: false })
  }
  setAppProp = (obj: any, callback?: any) => {
    let appConfig = { ...this.state };
    appConfig = { ...appConfig, ...obj };
    this.setState(appConfig, () => {
      if (callback) {
        callback();
      }
    });
  }

  setScreen = (screen: string, navigate?: boolean, params?: any, callback?: any) => {
    if (!this.firstScreen) {
      this.firstScreen = screen;
    } else {
      if (!navigationService.props.setHome) {
        navigationService.resetRoutes();
        navigationService.props.setHome = true;
      }
    }
    this.setState({
      screen, routeParams: params
    }, () => {
      if (navigate) {
        navigationService.navigate(screen, params)
        this.logEvent(`navigate_${screen}`);
      }
      if (callback) {
        callback();
      }

    })
  };


  setURLOpener() {
    if (Platform.OS === 'web') {
      return;
    }
    const opener = (url: string) => {
      //Browser.open({ url })
      InAppBrowser.open(url)
    };
    const config: any = Auth.configure(null);
    config.oauth.urlOpener = opener;
    Auth.configure(config);
  }

  signOut = async () => {
    this.setUser(null);
    this.forceUpdate();
    this.setURLOpener();
    await Auth.signOut({ global: true });
    InAppBrowser.close();


    //this.setScreen('Home', true);

  }
  updateAccount = async (date: any) => {

  }
  deleteUser = async () => {


  }
  logEvent = (eventName: string) => {

    const eve = `${Platform.OS.toString().toLowerCase()}_${eventName}`;
    try {
      if (Platform.OS === "web") {
        //ReactGA.event({ category: "", action: eve });
      } else {
        analytics().logEvent(eve, { platform: Platform.OS.toString().toLowerCase() });
      }
    } catch (e) {
      console.log("log error", JSON.stringify(e))
    }
  }
  setUser = async (user: any, callback?: any) => {


  }
  appReview = async () => {
    if (InAppReview.isAvailable()) {
      InAppReview.RequestInAppReview()
        .then((hasFlowFinishedSuccessfully) => {
          // when return true in android it means user finished or close review flow
          console.log('InAppReview in android', hasFlowFinishedSuccessfully);

          // when return true in ios it means review flow lanuched to user.
          console.log(
            'InAppReview in ios has launched successfully',
            hasFlowFinishedSuccessfully,
          );

          // 3- another option:
          if (hasFlowFinishedSuccessfully) {
            // do something for ios
            // do something for android
          }

        })
        .catch((error) => {

          console.log("InAppReview error", error);
        });
    } else {
      console.log("InAppReview not available")
    }
  }
  error = (e: any) => {
    this.setState({ isBusy: false })
    this.showToast(e.message, 'error')
  }

  hideMenu = () => {
    const slideOutLeft = ({
      from: {
        transform: [{ translateX: 0 }], // Starts at its natural position
      },
      to: {
        transform: [{ translateX: -280 }], // Ends offscreen 100 px to the left
      },
    });
    //this.viewRef.fadeOut(1000).then(endState => setIsVisible(false));
    this.setState({ appMenuOpenedBack: false })
    navigationService.props.menuRef.animate(slideOutLeft, 200).then(() => {
      //make the navigationService.props.viewPostRef click trough
      this.setState({ appMenuOpened: false, appMenuOpenedBack: false })
    });

  }

  showToast = (message: string, type: 'error' | 'success' | 'info') => {
    let tp = type.toString();
    if (Platform.OS === 'web') {
      if (tp === 'error') {
        tp = 'danger';
      }
      const options: any = {
        placement: 'top',
        type: tp,
        duration: 3000,
        animationType: 'slide-in',
        animationDuration: 300,
        successColor: colors.primaryColor,
        offsetBottom: 70,
        offsetTop: 70,
        dangerColor: "#ad2b1a",
        warningColor: "orange",
        normalColor: "gray",
        textStyle: { fontSize: 15, color: "#ffffff" },
        swipeEnabled: true
      }
      toast.show(message, options);
    } else {
      this.props.Toast.show({
        type: tp,
        position: 'top',
        topOffset: 70,
        visibilityTime: 3000,
        text1: message,

      });
    }
    //toast.show("Show custom toast", { data: { title: 'Toast title' } })
  }

  render() {
    return (
      <AppContext.Provider value={
        {
          state: this.state,
          showToast: this.showToast,
          sendMail: this.sendMail,
          hideMenu: this.hideMenu,
          shareMe: this.shareMe,
          gotoStore: this.gotoStore,
          getAIMessage: this.getAIMessage,
          getChatMessage: this.getChatMessage,
          deleteThread: this.deleteThread,
          clearChat: this.clearChat,
          gotoChatHistory: this.gotoChatHistory,
          showDeleteThread: this.showDeleteThread,
          getListItems: this.getListItems,
          appReview: this.appReview,
          buyPremiumSubscription: this.buyPremiumSubscription,
          restorePurchase: this.restorePurchase,
          playRewardedAdAndGenerate: this.playRewardedAdAndGenerate,
          showRewarded: this.showRewarded,
          playRewardedAndGo: this.playRewardedAndGo,
          createAd: this.createAd,
          logEvent: this.logEvent,
          updateAccount: this.updateAccount,
          setUser: this.setUser,
          signOut: this.signOut,
          setScreen: this.setScreen,
          setAppProp: this.setAppProp,

        }}>
        {this.props.children}
      </AppContext.Provider>)
  }
}
