import { FontAwesome } from '@expo/vector-icons';
import { API } from 'aws-amplify';
import * as React from 'react';
import {
    StyleSheet,
    Text,
    View,
    ScrollView,
    Image
} from 'react-native';
import PrimaryButton from '../components/primary-button';

import colors from '../constants/colors';
import style from '../constants/style';
import navigationService from '../services/navigation-service';
import { AppContext, IContext } from "../store/app-provider";
import * as queries from "./../graphql/queries";
interface Props {

}

interface State {
    message?: any
}

class MessageScreen extends React.Component<Props, State> {
    static contextType = AppContext;
    declare context: IContext

    constructor(props: any) {
        super(props);
        this.state = {}
    }
    openApp() {

    }
    async componentDidMount() {
        setTimeout(async () => {

            let messageId = this.context.state.routeParams?.id;
            console.log("messageId", messageId);
            if (messageId) {
                const message = (await API.graphql({
                    query: queries.getChats,
                    variables: { id: messageId }
                }) as any).data.getChats;
                this.setState({ message: message });
                console.log("message", message);
            }

        }, 100);

    }

    render() {

        return (

            <View style={style.page}>
                <View style={[style.container, { justifyContent: 'flex-start' }]}>
                    {this.state.message ? <View style={[style.column, style.fullWidth, { marginTop: 20, marginBottom: 20, borderRadius: colors.borderRadius, backgroundColor: colors.pinkish, padding: 20 }]} >
                        <View style={[style.row, style.fullWidth, { paddingRight: 10 }]}>
                            <FontAwesome name="user-circle" size={24} style={{ marginRight: 10 }} color={colors.bluePrimary} />
                            <Text style={{
                                fontFamily: 'Jost',
                                fontSize: 18,
                                flexGrow: 1,
                                paddingRight: 20,
                                color: colors.neutralBlue
                            }}>{this.state.message.userMessage}</Text>
                        </View>
                        <View style={[style.row, { marginTop: 20 }]}>
                            {/* <MaterialCommunityIcons name="robot" size={24} style={{ marginRight: 10 }} color={colors.bluePrimary} /> */}
                            <Image style={[{
                                height: 24,
                                width: 24,
                                marginRight: 10
                            }]} source={{ uri: navigationService.props.logo }}></Image>
                            <Text style={{
                                fontFamily: 'Jost',
                                flexGrow: 1,
                                paddingRight: 20,
                                fontSize: 18,
                                color: colors.neutralBlue
                            }}>{this.state.message.assistantMessage}</Text>
                        </View>


                    </View> : null}
                    <View style={{ flex: 1 }}></View>
                    <PrimaryButton align={'right'} onPress={() => {
                        this.context.gotoStore();
                    }} label={'Noah, întreabă-l și tu pe Nea Ion'} style={{
                        width: '100%', padding: 10,
                        marginTop: 10, marginBottom: 10
                    }} />
                </View>
            </View>

        )
    };

}

export default MessageScreen;

const styles = StyleSheet.create({});
