import * as React from 'react';
import { ActivityIndicator, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { withTranslation } from 'react-i18next';
import { AppContext, IContext } from '../store/app-provider';
import ItemsList from "../components/items-list";
import { AntDesign, FontAwesome5, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { API } from "aws-amplify";
import * as queries from "../graphql/queries";
import ActionSheet, { SheetManager } from 'react-native-actions-sheet';
import Alert from '../components/alert';

import navigationService from "../services/navigation-service";




interface Props {

}

interface State {
    isBusy: boolean
}
class HistoryScreen extends React.Component<Props, State> {
    list: any;
    static contextType = AppContext;
    declare context: IContext
    constructor(props: any) {
        super(props);
        this.state = { isBusy: false }
    }

    async componentDidMount() {

    }

    async getMyChats(token?: string) {
        let history = (await API.graphql({
            query: queries.getThreadsByDeviceId,
            variables: { deviceId: this.context.state.deviceId, limit: 20, sortDirection: 'ASC', nextToken: token },
        }) as any).data.getThreadsByDeviceId;
        //console.log(history);
        return { items: history.items, nextToken: history.nextToken };
    }
    componentDidUpdate(prevPops: any) {


    }



    render() {

        const renderItem = ({ item, index }: any) => {
            return <TouchableOpacity onPress={() => {
                this.context.gotoChatHistory(item);
            }}>

                <View style={[style.column, style.fullWidth, { marginTop: 20, marginBottom: 10 }]} key={item.id}>
                    <View style={[style.row, style.fullWidth, style.vcenter, { paddingRight: 10 }]}>
                        <View style={[style.row, style.vcenter, { padding: 20, marginRight: 10, flex: 1, borderRadius: colors.borderRadius, backgroundColor: colors.pinkish, }]}>
                            <Text style={{
                                fontFamily: 'Jost',
                                fontSize: 18,
                                flex: 1,
                                paddingRight: 20,
                                color: colors.neutralBlue
                            }}>{item.message}</Text>
                            <AntDesign name="arrowright" size={24} color="black" />
                        </View>
                        <TouchableOpacity onPress={() => {
                            //this.submitText();
                            this.context.showDeleteThread(item.id);
                        }}>
                            <MaterialCommunityIcons name="delete" size={30} color={colors.bluePrimary} />
                        </TouchableOpacity>
                    </View>
                </View>

            </TouchableOpacity>

        }
        const busyIndicator = () => {
            if (this.state.isBusy) {
                return <ActivityIndicator size="large" style={style.busyIndicator} color={colors.busyIndicator} />
            }
        }


        return (

            <View style={style.page}>
                <View style={[style.container]}>
                    <ItemsList disableBottomScroll={true} ref={(ref) => {
                        this.list = ref;
                    }} cache={false} renderItem={renderItem} stateKey={'historyList'} fn={this.getMyChats.bind(this)} nextTokenName={'nextToken'} stateItem={this.context.state.historyList}></ItemsList>
                    {!this.context.state.isPremium && this.context.state.adData?.enabled && this.context.state.checkedStore && Platform.OS !== 'web' && this.context.state.appConfig ?
                        <View style={[{
                            marginTop: 10,
                            backgroundColor: '#fff',
                            height: 260,
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: 5,
                            width: '100%',
                        }]}>

                            <navigationService.props.BannerAd onAdFailedToLoad={() => {

                            }}
                                unitId={this.context.state.adData?.ban}
                                size={navigationService.props.BannerAdSize.MEDIUM_RECTANGLE}
                                requestOptions={{
                                    requestNonPersonalizedAdsOnly: this.context.state.requestNonPersonalizedAdsOnly
                                }}
                            />
                        </View> : null}
                </View>
                {busyIndicator()}
                <ActionSheet containerStyle={{
                    maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    borderRadius: colors.borderRadius
                }} defaultOverlayOpacity={0.5} id="delete_thread">
                    <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
                        <Alert icon={
                            <FontAwesome5 name="trash" size={20} color={colors.iconColorWhite} />} style={{ width: '100%' }} onCancel={() => {
                                SheetManager.hide('delete_thread')
                            }} onAccept={async () => {
                                SheetManager.hide('delete_thread')
                                this.setState({ isBusy: true });
                                await this.context.deleteThread();
                                this.list.refresh();
                                this.setState({ isBusy: false });
                            }} message={"Păi ești sigur că vrei să ștergi aceste mesaje?"} cancelLabel={"Noah, lasă!"} acceptLabel={"Dă-i bice!"} />
                    </View>

                </ActionSheet>
            </View>

        )
    };

}

export default (withTranslation()(HistoryScreen));

const styles = StyleSheet.create({
    input: {
        padding: 5,
        height: 35,
        width: '100%',
        borderBottomWidth: 1,
        borderBottomColor: colors.textColor,
        color: colors.textColor
    },
    screenItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        borderRadius: 10,
        width: '100%',
        height: '100%',
        backgroundColor: colors.textColor
    }
});