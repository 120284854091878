/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDevice = /* GraphQL */ `
  mutation CreateDevice(
    $input: CreateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    createDevice(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice(
    $input: UpdateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    updateDevice(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDevice = /* GraphQL */ `
  mutation DeleteDevice(
    $input: DeleteDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    deleteDevice(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateChats = /* GraphQL */ `
  mutation UpdateChats(
    $input: UpdateChatsInput!
    $condition: ModelChatsConditionInput
  ) {
    updateChats(input: $input, condition: $condition) {
      id
      deviceId
      threadId
      assistantId
      assistantMessage
      userMessage
      type
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteChats = /* GraphQL */ `
  mutation DeleteChats(
    $input: DeleteChatsInput!
    $condition: ModelChatsConditionInput
  ) {
    deleteChats(input: $input, condition: $condition) {
      id
      deviceId
      threadId
      assistantId
      assistantMessage
      userMessage
      type
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createThreads = /* GraphQL */ `
  mutation CreateThreads(
    $input: CreateThreadsInput!
    $condition: ModelThreadsConditionInput
  ) {
    createThreads(input: $input, condition: $condition) {
      id
      deviceId
      message
      threadId
      assistantId
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateThreads = /* GraphQL */ `
  mutation UpdateThreads(
    $input: UpdateThreadsInput!
    $condition: ModelThreadsConditionInput
  ) {
    updateThreads(input: $input, condition: $condition) {
      id
      deviceId
      message
      threadId
      assistantId
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteThreads = /* GraphQL */ `
  mutation DeleteThreads(
    $input: DeleteThreadsInput!
    $condition: ModelThreadsConditionInput
  ) {
    deleteThreads(input: $input, condition: $condition) {
      id
      deviceId
      message
      threadId
      assistantId
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createChats = /* GraphQL */ `
  mutation CreateChats(
    $input: CreateChatsInput!
    $condition: ModelChatsConditionInput
  ) {
    createChats(input: $input, condition: $condition) {
      id
      deviceId
      threadId
      assistantId
      assistantMessage
      userMessage
      type
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
