import { NavigationContainer } from "@react-navigation/native";

import navigationService from "../services/navigation-service";
import { AppContext, IContext } from "../store/app-provider";
import HomeScreen from './../screens/home';
import Toast from 'react-native-toast-notifications';
import * as Animatable from 'react-native-animatable';
import PrivacyScreen from './../screens/privacy';
import TermsScreen from './../screens/terms';
import MyAccountScreen from './../screens/my-account';
import LoginScreen from './../screens/login';
import MessageViewScreen from './../screens/message-view';
import HistoryScreen from './../screens/history';
import StatsScreen from './../screens/stats';
import { ToastProvider } from "react-native-toast-notifications";
import Header from './../components/header';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Constants from 'expo-constants';
const Stack = createNativeStackNavigator();
import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { Auth, Hub } from "aws-amplify";
import React from "react";
import colors from "../constants/colors";
import {
  AsyncStorage,
  Keyboard,
  Modal,
  Platform,
  BackHandler,
  ScrollView,
  Switch,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  StatusBar,
  ImageBackground
} from "react-native";
import style from "../constants/style";
import { AntDesign, Entypo, FontAwesome, FontAwesome5, SimpleLineIcons } from "@expo/vector-icons";
import PrimaryButton from "./primary-button";
import ActionSheet, { SheetManager } from "react-native-actions-sheet";
import Share from "./share";
import Alert from "./alert";
import AppMenu from "./app-menu";
import RadioGroup from "./radio-group";
import DoubleButtonBar from "./double-button-bar";

const slideInLeft = {
  from: {
    transform: [{ translateX: -280 }], // Starts offscreen 100 px to the left
  },
  to: {
    transform: [{ translateX: 0 }], // Ends at its natural position
  },
};

interface State {
  isBusy?: boolean;
  selectedLyricsTab: number;
}

interface Props {

}

const link = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {

      Home: {

        path: '/',
      },
      Privacy: {

        path: 'privacy',
      },

      MyAccount: {
        path: 'my-account',
      },
      Terms: {

        path: 'terms',
      }
      , Message: {

        path: 'message',
      },
      'Sign In': {
        path: 'login',
      }
    },

  },
}

class AppNavigator extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext
  appMenu = Platform.OS !== 'web' ? [{
    label: "Premium Subscribe",
    icon: "shopping-cart"
  }, {
    label: "Buy Premium",
    icon: "shopping-cart"
  }, {
    label: "Terms and Conditions",
    icon: "file-alt"
  }, {
    label: "Privacy Policy",
    icon: "file-alt"
  }] : [{
    label: "Terms and Conditions",
    icon: "file-alt"
  }, {
    label: "Privacy Policy",
    icon: "file-alt"
  }]

  menu = [{ label: "My Posts", icon: "book", screen: "MyPosts" }, {
    label: "My Liked",
    icon: "heart",
    screen: "MyLiked"
  }, {
    label: "My Account",
    icon: "user-circle",
    screen: "MyAccount"
  }]
  handler
  constructor(props: any) {
    super(props);
    this.state = {
      isBusy: false,
      selectedLyricsTab: 0
    }
    this.getUserCredentials();
    if (!this.handler) {
      this.handler = Hub.listen('auth', ({ payload: { event, data } }) => {

        switch (event) {
          case 'signIn':
            //console.log('auth state signIn', event);
            this.getUserCredentials();
            break;
          case 'signOut':

            break;
          case 'customOAuthState':

        }
      });
    }
  }

  componentDidMount() {

    const url = Linking.getInitialURL().then(url => {
      if (url) {
        const route = url.replace(/.*?:\/\//g, "");
        const routeSplit = route.split("?");
        if (routeSplit[0] === "view") {
          const id = routeSplit[1].split("=")[1];
          //navigationService.navigate("View", { id: id });
          this.context.setScreen("View", true, { id });
        }
        //console.log("init route", route)
      }
    });
  }

  async getUserCredentials() {
    let user;
    if (navigationService.props.initialNotification?.data?.screen === "Horoscope") {
      navigationService.props.initialNotification = null;
      this.context.setScreen('Horoscope', true)
    }
    try {
      user = await Auth.currentAuthenticatedUser();
      this.context.setUser(user);
      //(store.dispatch as ThunkDispatch<any, void, ActionTypes>)(checkUser(user))
      //console.log("Cognito has a user")
    } catch (e) {
      //console.log("Cognito has no user")
      //(store.dispatch as ThunkDispatch<User, void, ActionTypes>)(checkUser({ attributes: { "email": "yonodo10@gmail.com", "sub": "920a4dd7-6212-48a8-b873-b7f1a72a2901" } }))

    }

  }


  signOut = () => {
    SheetManager.hide('user_menu');
    this.context.signOut();

  }
  gotoMyScreen = (item: any) => {
    SheetManager.hide('user_menu');
    if (item.screen !== 'Feedback') {
      this.context.setScreen(item.screen, true);
    } else {
      setTimeout(() => {
        SheetManager.show(`feedback`);
        //this.context.showToast(`Feedback is not available on the mobile app`, 'success');
      }, 500);
    }
  }

  agreePolicy() {
    this.context.setAppProp({ hasShownPolicy: true });
    if (Platform.OS === "web") {
      localStorage.setItem("hasShownPolicy2", "true");
    } else {
      AsyncStorage.setItem("hasShownPolicy2", "true");
    }
  }


  navigationStateChange = (state: any) => {
    console.log("nav state", state)
    const screen = state?.routes?.[state.routes?.length - 1]?.name;
    const params = state?.routes?.[state.routes?.length - 1]?.params;
    this.context.setScreen(screen, false, params);
    console.log("navigationStateChange", screen, params);
    //(store.dispatch as ThunkDispatch<any, void, ActionTypes>)(setScreen(screen, params, true));

  }
  readyNav = () => {

    this.navigationStateChange(navigationService.getNavigation().getState())

  }

  render() {

    return (

      <NavigationContainer linking={link} onReady={this.readyNav.bind(this)} onStateChange={this.navigationStateChange.bind(this)} ref={navigatorRef => {
        navigationService.setTopLevelNavigator(navigatorRef);
      }}>

        <Stack.Navigator screenOptions={{
          animationDuration: 500,
          animation: 'simple_push',
          gestureEnabled: true,
          animationTypeForReplace: 'push'

        }}>
          <Stack.Screen
            name="Home"
            component={HomeScreen}
            options={{ headerTitleAlign: 'center', title: "Nea Ion", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="Sign In"
            component={LoginScreen}
            options={{ headerTitleAlign: 'center', title: "Nea Ion", header: (props) => <Header {...props} /> }}
          />

          <Stack.Screen
            name="MyAccount"
            component={MyAccountScreen}
            options={{ headerTitleAlign: 'center', title: "Nea Ion", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="Message"
            component={MessageViewScreen}
            options={{ headerTitleAlign: 'center', title: "Nea Ion", header: (props) => <Header {...props} /> }}
          />
          <Stack.Screen
            name="History"
            component={HistoryScreen}
            options={{ headerTitleAlign: 'center', title: "Nea Ion", header: (props) => <Header {...props} /> }}
          />

          <Stack.Screen
            name="Stats"
            component={StatsScreen}
            options={{ headerTitleAlign: 'center', title: "Nea Ion", header: (props) => <Header {...props} /> }}
          />


          <Stack.Screen
            name="Privacy"
            component={PrivacyScreen}
            options={{ headerTitleAlign: 'center', title: "Nea Ion", header: (props) => <Header {...props} /> }}
          />

          <Stack.Screen
            name="Terms"
            component={TermsScreen}
            options={{
              headerTitleAlign: 'center',
              title: "Nea Ion",
              header: (props) => <Header {...props} />
            }}
          />

        </Stack.Navigator>
        <Toast ref={(ref: any) => global['toast'] = ref} />

        {this.context.state.appMenuOpenedBack ? <TouchableOpacity onPress={() => {

          this.context.hideMenu();

        }} style={[style.fullSize, { position: 'absolute', top: Platform.OS !== 'android' ? Constants.statusBarHeight : 0, backgroundColor: 'rgba(0,0,0,0.3)' }]}>

        </TouchableOpacity> : null}
        {this.context.state.appMenuOpened ? <Animatable.View duration={300} ref={ref => navigationService.props.menuRef = ref} style={{ position: 'absolute', height: '100%', width: 280, top: Platform.OS !== 'android' ? Constants.statusBarHeight : 0 }} animation={slideInLeft}>{this.context.state.appMenuOpened ? <AppMenu style={{ width: '100%', height: '100%' }} /> : null}</Animatable.View> : null}




        <ActionSheet containerStyle={{
          maxWidth: 500, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="go_ad">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert cancelLabel={('Buy Premium')} onCancel={async () => {
              await SheetManager.hide('go_ad')

              setTimeout(() => {
                SheetManager.show('buy_premium');
              }, 100);
            }} onAccept={() => {
              SheetManager.hide('go_ad');
              this.context.playRewardedAdAndGenerate();

            }} message={('Buy the Premium package or play a 30 seconds rewarded video ad')} acceptLabel={('Play Ad')} />
          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: 500, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="go_premium_sub">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert cancelLabel={('Cancel')} onCancel={async () => {
              SheetManager.hide('go_premium_sub')
            }} onAccept={async () => {
              await SheetManager.hide('go_premium_sub')
              setTimeout(() => {
                SheetManager.show('buy_premium')
              }, 100);

            }} message={('This feature is available only for premium subscribers')} acceptLabel={`Subscribe`} />
          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: 500, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="go_ad_view">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert cancelLabel={('Cumpără Premium')} onCancel={async () => {
              await SheetManager.hide('go_ad_view')
              setTimeout(() => {
                SheetManager.show('buy_premium');
              }, 100);
            }} onAccept={() => {
              SheetManager.hide('go_ad_view');
              this.context.playRewardedAndGo();
            }} message={('Cumpără pachetul Premium sau urmărește o reclamă recompensată de 30 de secunde')} acceptLabel={('Rulează reclamă')} />
          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: 500, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="buy_premium">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <View style={[style.fullWidth, style.column, style.hcenter, { height: 150, marginTop: 10 }]}>
              <Text style={{
                marginLeft: 0,
                width: '100%',
                fontSize: 16,
                marginBottom: 10,
                fontFamily: 'Jost'
              }}>Abonament Premium Lunar - Abonament reînnoibil lunar, elimină toate reclamele din aplicație.</Text>
              <PrimaryButton onPress={async () => {
                await SheetManager.hide('buy_premium');
                setTimeout(() => {
                  this.context.buyPremiumSubscription(false);
                }, 100);
              }} adiacentMessage={`$${this.context.state.appConfig?.[Platform.OS.toString() + "_" + "premiumSubPrice"]}/lună reînnoibil`} label="Abonează-te"></PrimaryButton>
            </View>
            {Platform.OS === 'android' ? <Text style={{ marginTop: 5 }}>
              Atinge aici pentru a gestiona sau anula abonamentul tău.
            </Text> : null}
            {Platform.OS === 'android' ? <PrimaryButton onPress={async () => {
              await SheetManager.hide('buy_premium');
              setTimeout(() => {
                //navigate to this url https://play.google.com/store/account/subscriptions
                Linking.openURL('https://play.google.com/store/account/subscriptions');
              }, 100);
            }} label="Gestionează abonamentul" style={{ marginTop: 10, width: '100%' }} isSecondary={true}></PrimaryButton> : null}
            <Text style={{ marginTop: 5 }}>
              Atinge aici pentru a restaura un pachet achiziționat anterior.
            </Text>
            <View style={[style.fullWidth, style.column, style.hcenter, { marginTop: 15, paddingBottom: 15, paddingTop: 0 }]}>
              <PrimaryButton onPress={async () => {
                await SheetManager.hide('buy_premium');
                setTimeout(() => {
                  this.context.restorePurchase();
                }, 100);
              }} label="Restaurează achiziția" style={{ width: '100%' }} isSecondary={true}></PrimaryButton>
            </View>
          </View>

        </ActionSheet>
        {
          this.context.state.shareModalVisible ? <Modal
            animationType="fade" style={{
              display: "flex",
              flexDirection: 'column',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            transparent={true}
            visible={this.context.state.shareModalVisible}
            onRequestClose={() => {
              // Alert.alert("Modal has been closed.");

            }}
          ><TouchableOpacity onPress={() => {
            this.context.setAppProp({ shareModalVisible: false })
          }} style={style.modalOverlay}>
              <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                <View style={{
                  width: 350,
                  height: 380,
                  padding: 20,
                  backgroundColor: colors.backgroundColor,
                  borderRadius: colors.borderRadius
                }}>
                  <Share onShare={() => {
                    this.context.setAppProp({ shareModalVisible: false })
                  }}></Share>
                </View>
              </View>
            </TouchableOpacity>
          </Modal> : null
        }

        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="app_review">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert style={{ width: '100%' }} cancelLabel={"Noah, lasă!"} onCancel={async () => {
              SheetManager.hide('app_review');
            }} onAccept={async () => {
              await SheetManager.hide('app_review')
              setTimeout(() => {
                this.context.appReview();
              }, 500);
            }} message={"Noah, ho! Dacă-ți place să vorbești cu mine, ia o pauză și țupă-mi un review și-apoi mai vorbim. Mulțam tare!"} acceptLabel={"Dă-i bice!"} />
          </View>

        </ActionSheet>
        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id="download_sheet_home">
          <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
            <Alert icon={Platform.OS === "android" ?
              <Entypo name="google-play" size={24} color={colors.iconColorWhite} /> :
              <FontAwesome5 name="app-store" size={24} color={colors.iconColorWhite} />} cancelLabel={"Noah, lasă!"} style={{ width: '100%' }} onCancel={() => {
                SheetManager.hide('download_sheet_home');
              }} onAccept={() => {
                this.context.gotoStore();
                SheetManager.hide('download_sheet_home')
              }} message={"Noah, stai și instalează aplicația!"} acceptLabel={"Dă-i bice!"} />
          </View>

        </ActionSheet>


        <ActionSheet containerStyle={{
          maxWidth: colors.maxWidth, borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderRadius: colors.borderRadius
        }} defaultOverlayOpacity={0.5} id={`user_menu`}>
          <View style={[style.fullWidth, style.vcenter, style.hcenter, { padding: 20 }]}>
            {this.menu.map((item: any, index: any) => {
              return <TouchableOpacity key={index} onPress={() => {
                this.gotoMyScreen(item)
              }} style={[style.fullWidth]}>
                <View style={[style.row, style.fullWidth, { marginTop: 10, padding: 10 }]}>
                  <FontAwesome5 name={item.icon} size={24} style={{ marginTop: 0 }} color="black" />
                  <Text style={{
                    marginLeft: 20,
                    flex: 1,
                    fontSize: 18,
                    fontFamily: 'Jost'
                  }}>{item.label}</Text>
                </View>
              </TouchableOpacity>

            })}

            <PrimaryButton align={'right'} onPress={() => {
              this.signOut()
            }} icon={
              <FontAwesome style={{ flexDirection: 'row-reverse' }} size={20} color={colors.iconColorWhite} name='sign-out'></FontAwesome>} label={'Sign Out'} style={{
                width: '100%',
                marginTop: 10, marginBottom: 10
              }} />
          </View>
        </ActionSheet>

        {this.context.state.appConfig?.privacy && this.context.state.hasShownPolicy === 'false' && Platform.OS !== 'web' ?
          <Modal
            animationType="fade" style={{
              display: "flex",
              bottom: 0,
              top: 0,
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              justifyContent: 'flex-end',
              alignItems: 'flex-end'
            }}

            transparent={true}
            visible={this.context.state.appConfig?.privacy && this.context.state.hasShownPolicy === 'false'}

          >
            <View style={[style.fullWidth, style.column, {
              height: '100%',
              justifyContent: 'flex-end',
              alignItems: 'center',
              backgroundColor: 'rgba(0,0,0,0.5)',
              padding: 0,
            }]}>

              <View style={[{
                height: '100%',
                width: '100%',
                maxWidth: colors.maxWidth,
                padding: 20,
                borderRadius: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                paddingTop: 0,
                backgroundColor: "#fff"
              }]}>
                <ScrollView style={[style.fullWidth, { padding: 20, flex: 1 }]}>
                  <Text style={[style.fullSize, { color: colors.textColor }]}>
                    {this.context.state.appConfig?.privacy?.replace(/<br\/>/gi, '\n') + '\n\n'}
                    {this.context.state.appConfig?.terms?.replace(/<br\/>/gi, '\n')}
                  </Text>
                </ScrollView>
                <PrimaryButton align={'left'} onPress={this.agreePolicy.bind(this)}
                  label={'Accept and Continue'} style={{ width: '100%', marginTop: 10 }} />
              </View>

            </View>
          </Modal> : null}

      </NavigationContainer>
    )
  }
}

export default AppNavigator;
