import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle, Platform, AsyncStorage } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import { AntDesign, FontAwesome, FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';
import { SheetManager } from 'react-native-actions-sheet';
import PrimaryButton from './primary-button';
import * as Animatable from 'react-native-animatable';
interface State {
    appMenu?: any
    blinkMenu?: boolean,
    showBlinkHoroscope?: boolean
    showBlinkNews?: boolean
    showRiddles?: boolean
}
interface Props {
    style?: ViewStyle
}
const fadeInOut = {
    0: {
        opacity: 0.5
    },
    0.5: {
        opacity: 1
    },
    1: {
        opacity: 0.5
    }
};
interface ItemType {
    label: string,
    icon: string,
    visibleKey: string,
    type?: string
}
class AppMenu extends React.Component<Props, State>  {
    static contextType = AppContext;
    declare context: IContext
    noClicks: number = 0;
    constructor(props: any) {
        super(props);

        this.state = {
            blinkMenu: false,
            showBlinkHoroscope: false,
            showBlinkNews: false,

            appMenu: [
                {
                    label: "Istoric",
                    icon: "history",
                    type: "material"
                }, {
                    label: "Premium",
                    icon: "shopping-cart"
                }, {
                    label: "Termeni și condiții",
                    icon: "file-alt"
                }, {
                    label: "Politica de confidențialitate",
                    icon: "file-alt"
                }]
        }

    }
    checkNumber() {
        this.noClicks++;
        console.log(this.noClicks);
        if (this.noClicks === 20) {
            this.context.setAppProp({ showStats: true }, () => {
                if (Platform.OS !== "web") {
                    AsyncStorage.setItem('showStats', 'true');
                } else {
                    localStorage.setItem('showStats', 'true');
                }
            })
        }
    }
    itemPress = async (label: string) => {
        this.context.setAppProp({ appMenuOpened: false, appMenuOpenedBack: false })
        switch (label) {



            case "Termeni și condiții":
                SheetManager.hide("menu_sheet");
                this.context.setScreen('Terms', true);
                break;
            case "Politica de confidențialitate":
                SheetManager.hide("menu_sheet");
                this.context.setScreen('Privacy', true);
                break;

            case 'Premium':
                SheetManager.hide("menu_sheet");
                setTimeout(() => {
                    SheetManager.show('buy_premium');
                }, 500)

                break
            case 'Istoric':
                this.context.setScreen('History', true);
                break
            case 'Statistici':
                this.context.setScreen('Stats', true);
                break


        }
    }
    async checkMenusItems() {
        if (Platform.OS === 'web') return;
        const [hasSeenNews, hasSeenHoroscope, hasSeenRiddles] = await Promise.all([AsyncStorage.getItem('hasSeenNews'), AsyncStorage.getItem('hasSeenHoroscope'), AsyncStorage.getItem('hasSeenRiddles')]);
        if (!hasSeenNews) {
            this.setState({ blinkMenu: true, showBlinkNews: true })
        }
        if (!hasSeenHoroscope) {
            this.setState({ blinkMenu: true, showBlinkHoroscope: true })
        }
        if (!hasSeenRiddles) {
            this.setState({ blinkMenu: true, showRiddles: true })
        }
    }
    async componentDidMount() {
        this.checkMenusItems();

        //if (Platform.OS !== "web" && !this.context.state.isPremium && !this.context.state.user?.isOnCampaign) {
        const showStats = Platform.OS !== "web" ? await AsyncStorage.getItem('showStats') : localStorage.getItem('showStats');
        if (showStats) {
            this.setState({ appMenu: [...this.state.appMenu, { label: "Statistici", icon: "chart-line", visibleKey: "showStats" }] })
        } else {
            this.setState({ appMenu: [...this.state.appMenu] })
        }



    }


    render() {

        return (
            <View style={[this.props.style, style.column, { backgroundColor: "#ffffff", paddingTop: 20, paddingLeft: 10, paddingRight: 10 }]}>
                {this.state.appMenu.map((item: ItemType, index) => {
                    return <TouchableOpacity key={index} onPress={() => {
                        this.itemPress(item.label)
                    }}>
                        <Animatable.View animation={this.state[item.visibleKey] ? fadeInOut : undefined} style={[style.row, style.fullWidth, style.vcenter, { padding: 10 }]} duration={1000} iterationCount="infinite">

                            <View style={[style.row, style.vcenter, style.hcenter, { width: 38, height: 38, borderRadius: 19, backgroundColor: colors.pinkish }]}>
                                {item.type === "material" ? <MaterialCommunityIcons name={item.icon} size={18} color={colors.greaysh} /> : <FontAwesome5 name={item.icon} size={18} color={colors.greaysh} />}
                            </View>
                            <Text style={[{ marginLeft: 15, color: colors.neutralBlue, fontFamily: 'Jost', fontSize: 16 }]}>{item.label}</Text>
                        </Animatable.View>
                    </TouchableOpacity>
                })}
                <View style={{ flex: 1 }}></View>
                {Platform.OS !== "web" ? <TouchableOpacity onPress={this.checkNumber.bind(this)}>
                    <View style={[style.fullWidth, { height: 80, backgroundColor: 'transparent' }]}></View>
                </TouchableOpacity> : null}
                {/*  {this.context.state.user ? <PrimaryButton align={'right'} onPress={() => {

                    this.context.setAppProp({ appMenuOpened: false, appMenuOpenedBack: false }, () => {
                        this.context.signOut();
                    })
                }} icon={
                    <FontAwesome style={{ flexDirection: 'row-reverse' }} size={20} color={colors.iconColorWhite} name='sign-out'></FontAwesome>} label={'Sign Out'} style={{
                        width: '100%',
                        marginTop: 10, marginBottom: 10
                    }} /> : <PrimaryButton align={'left'} onPress={() => {
                        this.context.setScreen("Sign In", true);
                        this.context.setAppProp({ appMenuOpened: false, appMenuOpenedBack: false })
                    }} icon={
                        <FontAwesome style={{}} size={20} color={colors.iconColorWhite} name='user'></FontAwesome>} label={'Sign In'} style={{
                            width: '100%',
                            marginTop: 10, marginBottom: 10
                        }} />} */}
            </View>
        )
    };

}


const styles = StyleSheet.create({
    mainTitle: {

        color: colors.secondaryColor,
        fontSize: 35,
        fontWeight: 'bold'
    },
    pageTitle: {
        width: '100%',
        textAlign: 'center',
        color: colors.secondaryColor,
        fontSize: 15,
        fontWeight: 'bold'
    },
});



export default AppMenu;
